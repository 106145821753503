<template>
  <div>
    <!-- 头部 -->
    <div class="head">
      <div class="head-item w d-flex align-center">

      </div>
    </div>
    <div class="content">
      <div class="w">
        <div class="login-box">
          <div class="login-title">登录优仕教育</div>
          <div v-if="loginIndex == 0">
            <div class="login-tab d-flex align-center justify-center">
              <div
                class="tab-item d-flex justify-center cursor"
                @click="selectTab(0)"
                :class="{ tab_active: tabIndex == 0 }"
              >
                普通登录
              </div>
              <div
                class="tab-item d-flex justify-center cursor"
                @click="selectTab(1)"
                :class="{ tab_active: tabIndex == 1 }"
              >
                手机快速登录
              </div>
            </div>
            <!-- 普通登录 -->
            <div v-if="tabIndex == 0">
              <div class="name">
                <input
                  class="name-input"
                  type="text"
                  ref="name"
                  v-model="name"
                  placeholder="手机号/用户名"
                />
                <img
                  class="name-img"
                  src="../../assets/img/login_1.png"
                  alt=""
                />
              </div>
              <div class="password">
                <input
                  type="password"
                  class="password-input"
                  ref="password"
                  v-model="password"
                  placeholder="密码"
                />
                <img
                  class="password-img"
                  src="../../assets/img/login_2.png"
                  alt=""
                />
              </div>
              <div class="code-box d-flex justify-between align-center">
                <div class="code">
                  <input
                    class="code-input"
                    type="text"
                    ref="code"
                    v-model="code"
                    placeholder="请输入右侧验证码"
                  />
                  <img
                    class="code-img"
                    src="../../assets/img/login_3.png"
                    alt=""
                  />
                </div>
                <div class="code-right cursor" @click="refreshCode">
                  <img :src="url" alt="点击刷新验证码" />
                  <!-- <div v-html="url"></div> -->
                </div>
              </div>
              <div class="error">{{ error }}</div>
              <img
                class="login-btn cursor"
                v-on:keyup.enter="passwordLogin"
                @click="passwordLogin"
                src="../../assets/img/login_btn.png"
                alt=""
              />
              <!-- <div class="wechat cursor"></div> -->
            </div>
            <!-- 短信登录 -->
            <div v-if="tabIndex == 1">
              <div class="name">
                <input
                  class="name-input"
                  type="text"
                  ref="phone"
                  v-model="phone"
                  placeholder="手机号/用户名"
                  maxlength="11"
                />
                <img
                  class="name-img"
                  src="../../assets/img/login_1.png"
                  alt=""
                />
              </div>
              <div class="code-box d-flex justify-between align-center">
                <div class="code">
                  <input
                    class="code-input"
                    type="text"
                    ref="imgcode"
                    v-model="imgcode"
                    placeholder="请输入右侧验证码"
                  />
                  <img
                    class="code-img"
                    src="../../assets/img/login_3.png"
                    alt=""
                  />
                </div>
                <div class="code-right cursor" @click="refreshCode">
                  <img :src="url" alt="" />
                </div>
              </div>
              <div class="sms-box d-flex justify-between align-center">
                <div class="sms">
                  <input
                    class="sms-input"
                    type="text"
                    ref="smscode"
                    v-model="smscode"
                    placeholder="请输入短信验证码"
                  />
                  <img
                    class="sms-img"
                    src="../../assets/img/login_4.png"
                    alt=""
                  />
                </div>
                <div
                  class="code-btn d-flex align-center justify-center cursor"
                  @click="getsmsCode"
                  v-if="show"
                >
                  免费获取短信码
                </div>
                <div
                  class="code-btn d-flex align-center justify-center cursor"
                  v-if="!show"
                >
                  {{ count }}后重新获取
                </div>
              </div>
              <div class="error">{{ error }}</div>
              <img
                class="login-btn cursor"
                @click="smsLogin"
                src="../../assets/img/login_btn.png"
                alt=""
              />
            </div>
          </div>
          <!-- 注册 -->
          <div class="register" v-if="loginIndex == 1">
            <div class="name">
              <input
                class="name-input"
                type="text"
                ref="rephone"
                v-model="rephone"
                placeholder="请填写手机号"
                v-on:input="animateWidth()"
                maxlength="11"
              />
              <img class="name-img" src="../../assets/img/login_1.png" alt="" />
            </div>

            <div class="password">
              <input
                class="password-input"
                type="password"
                ref="repassword"
                v-model="repassword"
                placeholder="6-12位，包含字母、数字、标点符号任2种"
                maxlength="12"
                @blur="passwodrWidth()"
              />
              <img
                class="password-img"
                src="../../assets/img/login_2.png"
                alt=""
              />
            </div>
            <div class="code-box d-flex justify-between align-center">
              <div class="code">
                <input
                  class="code-input"
                  type="text"
                  placeholder="请输入短信验证码"
                  ref="recode"
                  v-model="recode"
                />
                <img
                  class="code-img"
                  src="../../assets/img/login_3.png"
                  alt=""
                />
              </div>
              <div
                class="code-btn d-flex align-center justify-center cursor"
                @click="sendCodeNotImgCode"
                v-if="shows"
              >
                免费获取短信码
              </div>
              <div
                class="code-btn d-flex align-center justify-center cursor"
                v-if="!shows"
              >
                {{ counts }}后重新获取
              </div>
            </div>
            <div class="error">{{ error }}</div>
            <img
              class="login-btn cursor"
              src="../../assets/img/login_5.png"
              alt=""
              @click="registerBtn"
            />
            <div class="register-pact">
              注册代表您已经阅读并同意
              <span class="cursor"> 《用户协议》 </span>
            </div>
          </div>
          <div
            v-if="loginIndex == 0"
            class="login-botttom d-flex align-center justify-center"
          >
            没有账号？
            <span class="cursor" @click="selectLogin(1)"> 免费注册 </span>
          </div>
          <div
            v-if="loginIndex == 1"
            class="login-botttom d-flex align-center justify-center"
          >
            已有账号？

            <span class="cursor" @click="selectLogin(0)"> 立即登录 </span>
          </div>
        </div>
      </div>
    </div>
    <foots></foots>
  </div>
</template>

<script>
import config from "../../utils/config";
export default {
  data() {
    return {
      error: "",
      tabIndex: 0,
      loginIndex: 0,
      url: "",
      name: "", //账号密码登录
      password: "", //账号密码登录
      code: "", //账号密码登录
      smscode: "", //短信登录
      phone: "", //短信登录
      imgcode: "", //短信登录
      recode: "", //注册
      rephone: "", //注册
      repassword: "", //注册
      count: "",
      counts: "",
      timer: null,
      timers: null,
      show: true,
      shows: true,
      iShow: false
    };
  },
  created() {
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        if (that.loginIndex == 0) {
          if (that.tabIndex == 0) {
            that.passwordLogin();
          } else {
            that.smsLogin();
          }
        } else {
          that.registerBtn();
        }
        // 登录方法名
        return false;
      }
    };
    if (this.$route.params.loginIndex) {
      this.loginIndex = this.$route.params.loginIndex;
    }
  },
  mounted() {
    this.getcode();
  },
  methods: {
    goTo(id) {
      this.$router.push(id);
    },
    // async getcode(params) {
    //   var timestamp = new Date().getTime();
    //   let _this = this;
    //   // let data = _this.id
    //   let data = {
    //     key: 'StudentImgCode'
    //   }
    //   const res = await this.$ajaxRequest('get', 'nextRandCode', data)
    //   // let url = URL.createObjectURL(res)
    //   // console.log(window.URL.createObjectURL(res))
    //   //  let img = document.createElement('img')
    //   //   img.onload = function(e) {
    //   //       window.URL.revokeObjectURL(img.src)
    //   //   }
    //   //   img.src =
    //   //   document.getElementById('verifyCode').appendChild(img)

    //   this.url =
    //     config.baseURL +
    //     "/Service-Student/api/nextRandCode?key=" + 'StudentImgCode';
    //   // this.url = res
    //   // _this.liveList = res.data.list;
    //   // if (res.data.isSign == 0 && _this.popupNum == 0) {
    //   //   _this.popup();
    //   //   _this.popupNum = 1;
    //   // }
    // },
    getcode() {
      var timestamp = new Date().getTime();
      this.url =
        config.baseURL +
        "/Service-Student/api/nextRandCode?key=" + 'StudentImgCode'+'&t='+timestamp;
    },
    selectTab(id) {
      this.tabIndex = id;
      this.error = "";
      this.name = ""; //账号密码登录
      this.password = ""; //账号密码登录
      this.code = ""; //账号密码登录
      this.smscode = ""; //短信登录
      this.phone = ""; //短信登录
      this.imgcode = ""; //短信登录
      this.recode = ""; //注册
      this.rephone = ""; //注册
      this.repassword = ""; //注册
    },
    selectLogin(index) {
      this.loginIndex = index;
      this.error = "";
      this.name = ""; //账号密码登录
      this.password = ""; //账号密码登录
      this.code = ""; //账号密码登录
      this.smscode = ""; //短信登录
      this.phone = ""; //短信登录
      this.imgcode = ""; //短信登录
      this.recode = ""; //注册
      this.rephone = ""; //注册
      this.repassword = ""; //注册
    },
    //刷新图形验证码
    refreshCode() {
      this.getcode();
    },
    //账号密码登录
    passwordLogin() {
      let _this = this;
      let name = this.$refs.name.value;
      let password = this.$refs.password.value;
      let code = this.$refs.code.value;
      if (name == "") {
        _this.error = "请输入用户名";
      } else if (password == "") {
        _this.error = "请输入密码";
      } else if (code == "") {
        _this.error = "请输入验证码";
      } else {
        _this.error = "";
        let data = {
          uname: name,
          upass: password,
          imgCode: code
        }
        _this.login(data)
      }
    },
    async login(params) {
      let _this = this
      const res = await this.$ajaxRequest('post', 'login', params)
      console.log(res.data);
      if (res.code == 200) {
        // this.tabList = res.data;
        this.$message({
          message: res.msg,
          type: "success"
        });
        console.log(this.$route.query.redirect);
        window.localStorage.setItem("user", JSON.stringify(res.data));
        this.$store.commit("userInfo", res.data);
        this.$router.replace("/");
        // const redirect = this.$route.query.redirect || "/";
        // this.$router.replace(redirect);
      } else {
        this.getcode();
        _this.error = res.msg;
      }

    },
    // 短信验证码登录
    smsLogin() {
      let _this = this;
      let phone = _this.$refs.phone.value;
      let imgcode = _this.$refs.imgcode.value;
      let smscode = _this.$refs.smscode.value;
      if (phone == "") {
        _this.error = "请填写手机号";
      } else if (imgcode == "") {
        _this.error = "请输入图形验证码";
      } else if (smscode == "") {
        _this.error = "请输入短信验证码";
      } else {
        let data = {
          phone: phone,
          imgCode: imgcode,
          smsCode: smscode
        }
        _this.loginByPhone(data)
        _this.error = "";
      }
    },
    async loginByPhone(data) {
      let _this = this;
      // let data = _this.id
      const res = await this.$ajaxRequest('post', 'loginByPhone', data)
      if (res.code == 200) {
        // this.tabList = res.data;
        this.$message({
          message: res.msg,
          type: "success"
        });
        window.localStorage.setItem("user", JSON.stringify(res.data));
        this.$store.commit("userInfo", res.data);
        this.$router.replace("/");
      } else {
        this.getcode();
        _this.error = res.msg;
      }
    },
    // 注册获取短信验证码时间
    getSms() {
      let _this = this;
      if (_this.iShow) {
        const TIME_COUNT = 60;
        if (!_this.timers) {
          _this.counts = TIME_COUNT;
          _this.shows = false;
          _this.timers = setInterval(() => {
            if (_this.counts > 0 && _this.counts <= TIME_COUNT) {
              _this.counts--;
            } else {
              _this.shows = true;
              clearInterval(_this.timers);
              _this.timers = null;
            }
          }, 1000);
        }
      } else {
        _this.error = "请输入正确手机号";
      }
    },
    // 获取短信登录验证码时间
    getSmsLogin() {
      let _this = this;
      if (_this.iShow) {
        const TIME_COUNT = 60;
        if (!_this.timer) {
          _this.count = TIME_COUNT;
          _this.show = false;
          _this.timer = setInterval(() => {
            if (_this.count > 0 && _this.count <= TIME_COUNT) {
              _this.count--;
            } else {
              _this.show = true;
              clearInterval(_this.timer);
              _this.timer = null;
            }
          }, 1000);
        }
      } else {
        _this.error = "请输入正确手机号";
      }
    },
    //发送注册验证码
    async sendCodeNotImgCode(params) {
      let _this = this;
      let rephone = _this.$refs.rephone.value;
      let data = {
        phone: rephone
      }
      const res = await this.$ajaxRequest('post', 'sendCodeNotImgCode', data)
      if (res.data == 200) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        _this.getSms();
      } else {
        _this.error = res.msg;
      }
    },
    // sendCodeNotImgCode() {
    //   let _this = this;
    //   let rephone = _this.$refs.rephone.value;
    //   if (this.iShow) {
    //     _this.$api
    //       .sendCodeNotImgCode({
    //         phone: rephone
    //       })
    //       .then(res => {
    //         this.$message({
    //           message: res.message,
    //           type: "success"
    //         });
    //         _this.getSms();
    //       })
    //       .catch(err => {
    //         console.log(err.msg);
    //         _this.error = err.msg;
    //       });
    //   }
    // },
    // 手机号失焦事件
    async queryByPhone(num) {
      let _this = this;
      let data = num
      const res = await this.$ajaxRequest('get', 'queryByPhone', '', data)
      console.log(res)
      if (res.data.phoneExist) {
        _this.iShow = true;
        _this.error = "";
      } else {
        _this.error = "手机号已被注册";
        _this.iShow = false;
      }
    },
    animateWidth() {
      let _this = this;
      let num = _this.$refs.rephone.value;
      if (num == "") {
        _this.iShow = false;
        return;
      }
      if (num != "") {
        if (!/^1[3456789]\d{9}$/.test(num)) {
          _this.error = "请输入正确手机号";
          _this.iShow = false;
        } else {
          _this.iShow = true;
          _this.error = "";
          _this.queryByPhone(num)
          // 验证是否注册
          // _this.$api
          //   .queryByPhone({
          //     phone: num
          //   })
          //   .then(res => {
          //     if (res.success) {
          //       _this.iShow = true;
          //       _this.error = "";
          //     } else {
          //       _this.error = "手机号已被注册";
          //       _this.iShow = false;
          //     }
          //   })
          //   .catch(err => {
          //     console.log(err);
          //     _this.error = err.message;
          //     _this.iShow = false;
          //   });
        }
      } else {
        // _this.title = "手机号已被注册";
        _this.iShow = false;
      }
    },
    //注册密码失焦时间
    passwodrWidth() {
      let _this = this;
      let num = _this.$refs.repassword.value;
      if (num.length < 6) {
        _this.error = "密码不能少于6位";
      } else {
        _this.error = "";
      }
    },
    // 注册按钮
    async register(rephone, repassword, recode) {
      let _this = this;
      let data = {
        phone: rephone,
        smsCode: recode,
        upass: repassword
      }
      const res = await this.$ajaxRequest('post', 'register', data)
      if (res.code == 200) {
        _this
          .$confirm(res.msg, {
            showCancelButton: false,
            showClose: false,
            confirmButtonText: "去登陆",
            type: "warning"
          })
          .then(res => {
            _this.loginIndex = 0;
            _this.rephone = "";
            _this.repassword = "";
            _this.recode = "";
          });
      } else {
        _this.error = res.msg;
      }
    },
    registerBtn() {
      let _this = this;
      let rephone = _this.$refs.rephone.value;
      let repassword = _this.$refs.repassword.value;
      let recode = _this.$refs.recode.value;
      if (rephone == "") {
        _this.error = "请输入手机号";
      } else if (repassword == "") {
        _this.error = "请输入密码";
      } else if (recode == "") {
        _this.error = "请输入验证码";
      } else {
        _this.error = "";
        _this.register(rephone, repassword, recode)
        // _this.$api
        //   .register({
        //     phone: rephone,
        //     smsCode: recode,
        //     upass: repassword
        //   })
        //   .then(res => {
        //     if (res.code == 0) {
        //       _this
        //         .$confirm(res.message, {
        //           showCancelButton: false,
        //           showClose: false,
        //           confirmButtonText: "去登陆",
        //           type: "warning"
        //         })
        //         .then(res => {
        //           _this.loginIndex = 0;
        //           _this.rephone = "";
        //           _this.repassword = "";
        //           _this.recode = "";
        //         });
        //     }
        //   })
        //   .catch(err => {
        //     console.log(err.msg);
        //     _this.error = err.msg;
        //   });
      }
    },
    //短信登录获取手机验证码
    async sendCode(phone, imgcode) {
      let _this = this;
      // let data = _this.id
      let data = {
        phone: phone,
        imgCode: imgcode
      }
      const res = await this.$ajaxRequest('post', 'sendCode', data)
      if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        _this.getSmsLogin();
      } else {
        _this.error = res.msg;
        this.getcode();
      }
    },
    getsmsCode() {
      let _this = this;
      let phone = _this.$refs.phone.value;
      let imgcode = _this.$refs.imgcode.value;
      let smscode = _this.$refs.smscode.value;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        _this.error = "请输入正确手机号";
      } else if (imgcode == "") {
        _this.error = "请输入图形验证码";
      } else {
        _this.error = "";
        _this.sendCode(phone, imgcode)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.tab_active {
  border-color: #5f2eff !important ;
  color: #333333 !important ;
}
// 头部
.head {
  max-width: 1920;
  min-width: 1200px;
  background-color: #fff;
  .head-item {
    height: 70px;
    .head-img {
      width: 128px;
      height: 34px;
    }
  }
}
.content {
  height: 772px;
  max-width: 1920px;
  min-width: 1200px;
  background: url(../../assets/img/login.png) center no-repeat;
  margin: 0 auto;
  padding-top: 105px;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
}
.login-box {
  position: relative;
  width: 380px;
  height: 546px;
  background: #ffffff;
  box-shadow: 0px 6px 14px 0px rgba(206, 212, 225, 0.5);
  border-radius: 10px;
  margin-left: auto;
  padding: 0 30px;
  box-sizing: border-box;
  &::after {
    content: "";
    width: 330px;
    height: 434px;
    background: #ffffff;
    box-shadow: 0px 6px 14px 0px rgba(206, 212, 225, 0.5);
    opacity: 0.6;
    border-radius: 10px;
    position: absolute;
    bottom: -17px;
    left: 25px;
    z-index: -2;
  }
  &::before {
    content: "";
    width: 290px;
    height: 400px;
    background: #ffffff;
    box-shadow: 0px 6px 14px 0px rgba(206, 212, 225, 0.5);
    opacity: 0.4;
    border-radius: 10px;
    position: absolute;
    bottom: -33px;
    left: 45px;
    z-index: -1;
  }
  .register {
    margin-top: 24px;
  }
  .login-title {
    font-size: 20px;
    font-weight: bold;
    color: #5f2eff;
    text-align: center;
    padding-top: 34px;
  }
  .login-tab {
    margin-top: 30px;
    margin-bottom: 24px;
    .tab-item {
      width: 90px;
      padding-bottom: 9px;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      color: #999999;
      font-size: 14px;
    }
    .tab-item + .tab-item {
      margin-left: 50px;
    }
  }
  .name {
    position: relative;
    .name-input {
      width: 320px;
      height: 44px;
      border: 1px solid #cccccc;
      border-radius: 6px;
      padding-left: 37px;
      box-sizing: border-box;
      color: #333333;
      font-size: 12px;
      &:focus {
        border-color: #5f2eff;
      }
      &::placeholder {
        font-size: 12px;
        color: #999999;
      }
    }
    .name-img {
      width: 14px;
      height: 19px;
      position: absolute;
      left: 13px;
      top: 13px;
    }
  }
  .password {
    margin-top: 28px;
    position: relative;
    .password-input {
      width: 320px;
      height: 44px;
      border: 1px solid #cccccc;
      border-radius: 6px;
      padding-left: 37px;
      box-sizing: border-box;
      color: #333333;
      font-size: 12px;
      &:focus {
        border-color: #5f2eff;
      }
      &::placeholder {
        font-size: 12px;
        color: #999999;
      }
    }
    .password-img {
      width: 18px;
      height: 20px;
      position: absolute;
      left: 13px;
      top: 13px;
    }
  }
  .code-box {
    margin-top: 28px;
    .code {
      position: relative;
      .code-input {
        width: 220px;
        height: 44px;
        border: 1px solid #cccccc;
        border-radius: 6px;
        padding-left: 37px;
        box-sizing: border-box;
        color: #333333;
        font-size: 12px;
        &:focus {
          border-color: #5f2eff;
        }
        &::placeholder {
          font-size: 12px;
          color: #999999;
        }
      }
      .code-img {
        width: 18px;
        height: 20px;
        position: absolute;
        left: 13px;
        top: 13px;
      }
    }
  }
  .code-right {
    width: 86px;
    height: 44px;
    border-radius: 4px;
    overflow: hidden;
  }
  .code-btn {
    width: 86px;
    height: 44px;
    background: #f8f6ff;
    border: 1px solid #5f2eff;
    border-radius: 4px;
    font-size: 11px;
    color: #5f2eff;
  }
  .sms-box {
    margin-top: 28px;
    .sms {
      position: relative;
      .sms-input {
        width: 220px;
        height: 44px;
        border: 1px solid #cccccc;
        border-radius: 6px;
        padding-left: 37px;
        box-sizing: border-box;
        color: #333333;
        font-size: 12px;
        &:focus {
          border-color: #5f2eff;
        }
        &::placeholder {
          font-size: 12px;
          color: #999999;
        }
      }
      .sms-img {
        width: 20px;
        height: 19px;
        position: absolute;
        left: 13px;
        top: 13px;
      }
    }
  }
  .sms-right {
    width: 86px;
    height: 44px;
    border-radius: 4px;
    background-color: skyblue;
  }
  .error {
    height: 20px;
    font-size: 12px;
    color: #f53444;
    line-height: 20px;
  }
  .login-btn {
    height: 53px;
    width: 333px;
    margin-top: 20px;
  }
  .wechat {
    width: 92px;
    height: 29px;
    margin: 0 auto;
    margin-top: 20px;
    background: url(../../assets/img/wechat.png) center no-repeat;
    &:hover {
      background: url(../../assets/img/wechat_active.png) center no-repeat;
    }
  }
  .login-botttom {
    width: 380px;
    height: 60px;
    background: #ebedff;
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 16px;
    color: #333333;
    span {
      font-size: 16px;
      color: #5f2eff;
    }
  }
  .register-pact {
    font-size: 12px;
    color: #999999;
    text-align: center;
    span {
      color: #5f2eff;
      font-size: 12px;
    }
  }
}
</style>
